import React from "react"
import { Container, Row, Col } from "reactstrap"
import "./footer.scss"

import List2 from "../components/list2"

import Smartphone from "../assets/svg/solid/mobile-alt.svg"
import Telephone from "../assets/svg/solid/phone-alt.svg"
import Mail from "../assets/svg/regular/envelope.svg"
import Pin from "../assets/svg/solid/map-marker-alt.svg"

import FacebookSVG from "../assets/svg/facebook.svg"
import InstagramSVG from "../assets/svg/instagram.svg"
import WhatsappSVG from "../assets/svg/whatsapp.svg"
import ViberSVG from "../assets/svg/viber.svg"

import classeMais from "../images/certifications/classeMais3.png"
import cortizo from "../images/certifications/cortizo2.jpg"
import wurth from "../images/certifications/wurth1.png"

const Footer = ({ siteMetadata }) => {
  const {
    title,
    about,
    email,
    social,
    contact,
    address,
    opening,
    author,
  } = siteMetadata
  const badges = [
    { img: wurth, alt: "Wurth" },
    { img: cortizo, alt: "Cortizo" },
    { img: classeMais, alt: "Classe +" },
  ]
  return (
    <footer>
      <section className="footer-wrapper">
        <Container className="footer-content text-white">
          <Row className="section-md">
            <Col className="company-badge" lg="4" sm="12">
              <h4 className="text-center font-weight-bold mt-4 mb-2">
                Certificações
              </h4>
              <section className="img-badge d-flex flex-wrap justify-content-around align-items-center">
                {badges.map(badge => (
                  <img
                    width="90px"
                    className="my-2"
                    src={badge.img}
                    alt={badge.alt}
                  />
                ))}
              </section>
            </Col>
            <Col className="footer-aboutus" lg="4" sm="6">
              <h4 className="text-center font-weight-bold mt-4 mb-2">
                Sobre nós
              </h4>
              <p className="font-size-80 text-justify">{about}</p>
              <section className="footer-social">
                <Row className="ml-0">
                  <a
                    href={social.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="facebook"
                  >
                    <FacebookSVG className="fill-svg" />
                  </a>
                  <a
                    href={social.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="instagram"
                  >
                    <InstagramSVG className="stroke-svg" />
                  </a>
                  <a
                    href={social.whatsapp}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp"
                  >
                    <WhatsappSVG className="fill-svg" />
                  </a>
                  <a
                    href={social.viber}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="viber"
                  >
                    <ViberSVG className="fill-svg" />
                  </a>
                </Row>
              </section>
              {/*
              <h6 className="font-weight-bold text-uppercase">Horário de funcionamento</h6>
              <section className="opening ml-5">
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Calendar />}
                  value={opening.day}
                />
                <hr />
                <List2
                  className="font-size-90"
                  svgSize="sm"
                  svg={<Clock />}
                  value={opening.hour}
                />
                <hr />
              </section>
              */}
            </Col>
            <Col className="footer-contact" lg="4" sm="6">
              <h4 className="text-center font-weight-bold mt-4 mb-2">
                Contactos
              </h4>
              <ul className="list-unstyled">
                <li className="my-2">
                  <List2 svg={<Smartphone />} value={contact.mobile} />
                </li>
                <li className="my-2">
                  <List2 svg={<Telephone />} value={contact.telephone} />
                </li>
                <li className="my-2">
                  <List2 svg={<Mail />} value={email} />
                </li>
                <li className="my-2">
                  <List2 svg={<Pin />} value={address} />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Row className="footer-credits justify-content-center">
          {` © ${title}  2024 | Code and design by: `}
          <a
            href={author.website}
            target="_blank"
            rel="noopener noreferrer"
            title={author.position}
          >
            {author.name}
          </a>
        </Row>
      </section>
    </footer>
  )
}

export default Footer
